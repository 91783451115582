<template>
    <div>
        <div v-if="preloading">
            <button class="btn btn-secondary"><i class="fas fa-cog fa-spin" /> {{ $t('global.loading') }}...</button>
        </div>
        <div v-else>
            <div class="box">
                <button class="btn btn-secondary" @click.prevent="newCoupon = true">
                    <i class="fas fa-plus-circle mr-2" />Ajouter un coupon
                </button>
                <div class="row pt-4">
                    <div class="col">
                        <b-table
                            :data="list"
                            :mobile-cards="true"
                            :debounce-search="700"
                            :paginated="true"
                            backend-pagination
                            :total="total"
                            :per-page="rows_per_page"
                            backend-filtering
                            backend-sorting
                            :striped="true"
                            :loading="loading"
                            @page-change="onPageChange"
                            @filters-change="onFilterChange"
                            @sort="onSortChange"
                        >
                            <b-table-column field="coupon" sortable searchable label="Coupon" cell-class="align-middle">
                                <div slot="default" slot-scope="props">
                                    {{ props.row.coupon }}
                                </div>
                                <div slot="searchable" slot-scope="props" class="searchableCol">
                                    <input
                                        v-model="props.filters[props.column.field]"
                                        type="text"
                                        class="form-control form-control-sm"
                                        placeholder="#"
                                    />
                                </div>
                            </b-table-column>
                            <b-table-column
                                field="reduction"
                                sortable
                                searchable
                                label="Réduction"
                                cell-class="align-middle"
                            >
                                <div slot="default" slot-scope="props">-{{ props.row.reduction }}%</div>
                                <div slot="searchable" slot-scope="props" class="searchableCol">
                                    <input
                                        v-model="props.filters[props.column.field]"
                                        type="text"
                                        class="form-control form-control-sm"
                                        placeholder="x%"
                                    />
                                </div>
                            </b-table-column>
                            <b-table-column
                                field="usage_unique"
                                sortable
                                searchable
                                label="Usage Unique"
                                cell-class="align-middle"
                            >
                                <div slot="default" slot-scope="props">
                                    {{ props.row.usage_unique ? 'Oui' : 'Non' }}
                                </div>
                                <div slot="searchable" slot-scope="props">
                                    <select
                                        v-model="props.filters[props.column.field]"
                                        class="form-control form-control-sm"
                                    >
                                        <option :value="props.filters['unique']" selected disabled hidden>
                                            Usage unique
                                        </option>
                                        <option value="">-</option>
                                        <option value="1">Oui</option>
                                        <option value="0">Non</option>
                                    </select>
                                </div>
                            </b-table-column>
                            <b-table-column field="actif" sortable searchable label="Actif" cell-class="align-middle">
                                <div slot="default" slot-scope="props">
                                    <span
                                        :class="[
                                            'badge text-center p-2',
                                            props.row.actif ? 'badge-success' : 'badge-danger'
                                        ]"
                                        >{{ props.row.actif ? 'Oui' : 'Non' }}</span
                                    >
                                </div>
                                <div slot="searchable" slot-scope="props">
                                    <select
                                        v-model="props.filters[props.column.field]"
                                        class="form-control form-control-sm"
                                    >
                                        <option :value="props.filters['activite']" selected disabled hidden>
                                            Actif
                                        </option>
                                        <option value="">-</option>
                                        <option value="1">Oui</option>
                                        <option value="0">Non</option>
                                    </select>
                                </div>
                            </b-table-column>
                            <b-table-column field="action" label="Action">
                                <div slot="default" slot-scope="props">
                                    <TabButton
                                        :icon-after="'fas fa-pencil-alt'"
                                        :color="'primary'"
                                        @click.native="edit(props.row)"
                                    />
                                    <TabButton
                                        :icon-after="'fas fa-trash-alt'"
                                        :color="'danger'"
                                        @click.native="deleteItem(props.row)"
                                    />
                                </div>
                            </b-table-column>
                        </b-table>
                    </div>
                </div>
            </div>
            <nl-modal :open-modal="newCoupon" title="Ajouter un coupon" @hidden="closeAddCoupon">
                <template>
                    <nl-alert ref="alert" />
                    <v-form ref="addCouponForm">
                        <div class="form-group">
                            <text-field
                                v-model="model.coupon"
                                label="Nom du code promo"
                                rules="required"
                                name="Nom du code promo"
                            />
                        </div>
                        <div class="form-group">
                            <nl-select
                                v-model="model.usage_unique"
                                :items="usageUnique"
                                name="Usage Unique ?"
                                label="Usage Unique ?"
                                rules="required"
                            />
                        </div>
                        <div class="form-group">
                            <text-field
                                v-model="model.reduction"
                                label="Pourcentage"
                                rules="required"
                                name="Pourcentage"
                            />
                        </div>
                    </v-form>
                </template>
                <template #footer>
                    <button class="btn btn-primary" @click="addCoupon">
                        {{ $t('global.save') }}
                    </button>
                    <button class="btn btn-danger" @click="closeAddCoupon">
                        {{ $t('global.cancel') }}
                    </button>
                </template>
            </nl-modal>
            <nl-modal :open-modal="editCoupon" title="Ajouter un coupon" @hidden="closeAddCoupon">
                <template>
                    <nl-alert ref="alert" />
                    <v-form v-if="editable" ref="addCouponForm">
                        <div class="form-group">
                            <text-field
                                v-model="editable.coupon"
                                label="Nom du code promo"
                                rules="required"
                                name="Nom du code promo"
                                disabled
                            />
                        </div>
                        <div class="form-group">
                            <nl-select
                                v-model="editable.usage_unique"
                                :items="usageUnique"
                                name="Usage Unique ?"
                                label="Usage Unique ?"
                                rules="required"
                            />
                        </div>
                        <div class="form-group">
                            <text-field
                                v-model="editable.reduction"
                                label="Pourcentage"
                                rules="required"
                                name="Pourcentage"
                            />
                        </div>
                        <div class="form-group">
                            <nl-select
                                v-model="editable.actif"
                                :items="actifs"
                                name="Actif ?"
                                label="Actif ?"
                                rules="required"
                            />
                        </div>
                    </v-form>
                </template>
                <template #footer>
                    <button class="btn btn-primary" @click="updateCoupon">
                        {{ $t('global.save') }}
                    </button>
                    <button class="btn btn-danger" @click="closeEditCoupon">
                        {{ $t('global.cancel') }}
                    </button>
                </template>
            </nl-modal>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'

    export default {
        title: 'layout.coupon-management',
        data: () => ({
            preloading: false,
            loading: false,
            rows_per_page: 15,
            model: {},
            usageUnique: [
                { text: 'Oui', id: 1 },
                { text: 'Non', id: 0 }
            ],

            actifs: [
                { text: 'Oui', id: 1 },
                { text: 'Non', id: 0 }
            ],
            newCoupon: false,
            editCoupon: false,
            editable: null
        }),
        computed: {
            ...mapGetters('coupon', ['list', 'total'])
        },
        mounted() {
            this.fetchCoupons()
        },
        methods: {
            ...mapActions('coupon', {
                getCoupons: 'list',
                createCoupon: 'create',
                update: 'update',
                deleteCoupon: 'delete'
            }),
            fetchCoupons() {
                this.loading = true

                var sort = this.sort
                if (this.sortOrder === 'desc') {
                    sort = '-' + this.sort
                }
                let filter = null
                if (this.filters) {
                    filter = []
                    Object.keys(this.filters).forEach(key => {
                        filter[key] = this.filters[key]
                    })
                }

                this.getCoupons({
                    sort: sort,
                    page: this.page,
                    rowsPerPage: this.rows_per_page,
                    filter: filter,
                    siteId: this.siteId
                })
                    .then(() => {})
                    .finally(() => {
                        this.loading = false
                    })
            },
            onPageChange(page) {
                this.page = page
                this.fetchCoupons()
            },
            onFilterChange(filters) {
                this.filters = filters
                this.fetchCoupons()
            },
            onSortChange(field, order) {
                this.sort = field
                this.sortOrder = order
                this.fetchCoupons()
            },
            addCoupon() {
                if (this.model.coupon && this.model.reduction && this.model.usage_unique) {
                    this.createCoupon(this.model)
                        .then(() => {
                            this.newCoupon = false
                            this.model = {}
                            this.fetchCoupons()
                        })
                        .catch(response => {
                            if (response.status == 422) {
                                this.$refs.alert.showValidationError(response.data)
                            } else {
                                this.$refs.alert.displayMsg('danger', response)
                            }
                        })
                }
            },
            closeAddCoupon() {
                this.model = {}
                this.newCoupon = false
            },
            edit(coupon) {
                this.editable = Object.assign({}, coupon)
                this.editCoupon = true
            },
            updateCoupon() {
                if (
                    this.editable.id &&
                    this.editable.coupon &&
                    this.editable.reduction &&
                    (this.editable.usage_unique || !this.editable.usage_unique)
                ) {
                    this.update(this.editable)
                        .then(() => {
                            this.editCoupon = false
                            this.editable = null
                            this.fetchCoupons()
                        })
                        .catch(response => {
                            if (response.status == 422) {
                                this.$refs.alert.showValidationError(response.data)
                            } else {
                                this.$refs.alert.displayMsg('danger', response)
                            }
                        })
                }
            },
            closeEditCoupon() {
                this.editable = null
                this.editCoupon = false
            },
            deleteItem(coupon) {
                confirm('Voulez-vous effacer ce coupon ?') &&
                    this.deleteCoupon(coupon.id)
                        .then(() => {
                            this.fetchCoupons()
                        })
                        .catch(response => {
                            if (response) {
                                alert(response.data.message)
                            }
                        })
                        .finally()
            }
        }
    }
</script>

<style></style>
